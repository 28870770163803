/* .header {
  padding-top: 64px;
  padding-bottom: 32px;
  width: 100%;
  padding: 130px;
  height: auto;
  text-align: center;
  background: #F0EEF3;
  background-image: url("./assets/img/header.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #F45197;
} */

header {
  position: relative;
  text-align: center;
}

.header2 {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

.header-button {
  position: absolute;
  width: 290px;
  bottom: 10px;
  left: 20px;
  /* background-color: #4CAF50; */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.watch_introduction {
  font-size: 18px;
  cursor: pointer;
  float: left;
  margin-top: 70px;
  color: black;
}

@media (max-width: 769px) {

  /* .header {
    padding-top: 64px;
    padding-bottom: 32px;
    width: 100%;
    height: 150px;
    background-image: url("./assets/img/header.png");
    background-size: 100% 100%;
  } */
  .header-button {
    position: absolute;
    width: 200px;
    bottom: 5px;
    left: 10px;
    /* background-color: #4CAF50; */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .watch_introduction {
    margin-top: 40px;
    margin-left: -70px;
  }
}

@media (max-width: 450px) {

  /* .header {
    padding-top: 64px;
    padding-bottom: 32px;
    width: 100%;
    height: 100px;
    background-image: url("./assets/img/header.png");
    background-size: 100% 100%;
  } */
  .header-button {
    position: absolute;
    width: 120px;
    bottom: 5px;
    left: 5px;
    /* background-color: #4CAF50; */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .watch_introduction {
    margin-top: 40px;
    margin-left: -60px;
    width: 100%;
  }
}

.popupIntroVDO {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: rgba(0, 0, 0, 0.95);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
}

.popupIntroVDO.active {
  visibility: visible;
  opacity: 1;
}

.popupIntroVDO video {
  position: relative;
  max-width: 900px;
  outline: none;
}

.popupIntroVDO .close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  filter: invert(1);
  max-width: 32px;
}

@media (max-width: 991px) {
  .popupIntroVDO video {
    max-width: 90%;
  }
}

.overlayPopupQuestionnaire {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: block;
}

.overlayPopupQuestionnaire.active {
  display: none;
}

.popupQuestionnaire {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: rgba(255, 255, 255, 1);
  width: 80%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  border-radius: 10px;
}

.popupQuestionnaire.active {
  visibility: visible;
  opacity: 1;
}

.popupQuestionnaire .close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  filter: invert(1);
  max-width: 32px;
}

@media (max-width: 991px) {
  .popupQuestionnaire video {
    max-width: 90%;
  }
}

.overlayPopupNews {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: block;
}

.overlayPopupNews.active {
  display: none;
}

.popupNews {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: rgba(255, 255, 255, 1);
  width: 80%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  border-radius: 10px;
}

.popupNews.active {
  visibility: visible;
  opacity: 1;
}

.popupNews .close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  filter: invert(1);
  max-width: 32px;
}

@media (max-width: 991px) {
  .popupNews video {
    max-width: 90%;
  }
}

.fa-bell {
  font-size: 40px;
  margin-right: 10px;
}

.bell-default {
  color: #fff;
  position: relative;
  transform-origin: top;
  cursor: pointer;
}

.bell-notification {
  color: #fff;
  position: relative;
  animation: bell 1s linear infinite alternate-reverse;
  transform-origin: top;
  cursor: pointer;
}

@keyframes bell {
  0% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

.bell-notification::after {
  content: attr(current-count);
  position: absolute;
  top: -10%;
  right: -0%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 3px solid #01111b;
}

.with-carrot {
  margin-top: 5%;
  color: #000000;
  text-align: center;
  font-size: clamp(4.5vw, 4.5vh, 84px);
  line-height: 1.2;
}

.with-week {
  color: #000000;
  display: block;
  font-size: clamp(3vw, 4.5vh, 64px);
}

.box-play {
  width: 210px;
  height: 44px;
  border-radius: 50px;
  border: 1px solid #ef60a3;
  margin-left: auto;
  margin-right: auto;
  display: block;
  display: flex;
  align-items: center;
  color: #ef60a3;
  font-size: 16px;
  font-weight: bold;
}

.play-circle {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 11px;
}

input[type="checkbox"] {
  accent-color: #ef60a3;
}
